import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Homepage } from '../utils/navLinks'
import Link from '../components/link'

const NotFoundPage = () => {
    const { t } = useTranslation('thankYou')

    return (
        <Layout>
            <SEO title="thankYou:title" description="thankYou:message" />
            <div className="thankYouPage">
                <h1 className="pageTitle">{t('header')}</h1>
                <p>{t('message')}</p>
                <Button
                    to={Homepage.path}
                    component={Link}
                    variant="contained"
                    disableElevation
                    className="homeButton"
                >
                    {t('link')}
                </Button>
            </div>
        </Layout>
    )
}

export default NotFoundPage
